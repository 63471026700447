import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body" }
const _hoisted_2 = {
  class: "container",
  id: "container"
}
const _hoisted_3 = { class: "form-container sign-up-container" }
const _hoisted_4 = { class: "form" }
const _hoisted_5 = { class: "email" }
const _hoisted_6 = { class: "form-container sign-in-container" }
const _hoisted_7 = { class: "form" }

import { onMounted, reactive, ref } from "vue";
import {
  UserControllerService,
  UserLoginDTO,
  UserRegisterDTO,
} from "../../../generated";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/UserStore";
import { useCommonStore } from "@/store/CommonStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserRegistView',
  setup(__props) {

const router = useRouter();
const userStore = useUserStore();
const commonStore = useCommonStore();
const BUttonText = ref("发送验证码");
const second = ref(60);
const getRegisterCodeButton = ref(null);
const timeId = ref(null);
const registerData = reactive({
  userAccount: "",
  userPassword: "",
  rePassword: "",
  captcha: "",
} as UserRegisterDTO);
const getRegisterCode = async () => {
  const res = await UserControllerService.getRegisterCode(
    registerData.userAccount as string
  );
  if (res.code !== 0) {
    ElMessage.error(res.message);
  }
  getRegisterCodeButton.value.disabled = true;
  timeId.value = setInterval(() => {
    BUttonText.value = second.value--;
    if (second.value < 0) {
      second.value = 60;
      if (timeId.value !== null && timeId.value !== "") {
        clearInterval(timeId.value);
      }
      BUttonText.value = "发送验证码";
      getRegisterCodeButton.value.disabled = false;
    }
  }, 1000);
};
const regist = async () => {
  const result = await UserControllerService.register(registerData);
  if (result.code === 0) {
    ElMessage.success("注册成功");
    router.push("/");
  } else {
    ElMessage.error(result.message);
  }
};

const loginData = reactive({
  userAccount: "",
  userPassword: "",
} as UserLoginDTO);
const login = async () => {
  const result = await UserControllerService.login(loginData);

  if (result.code === 0) {
    ElMessage.success("登陆成功");
    userStore.userInfo = result.data as any;
    userStore.getUserRole();
    commonStore.setActiveIndex(0);
    router.push({
      path: "/",
      replace: true,
    });
  } else {
    ElMessage.error(result.message);
  }
};
onMounted(() => {
  const signUpButton = document.getElementById("signUp");
  const signInButton = document.getElementById("signIn");
  const container = document.getElementById("container");

  // 检查元素是否存在，避免运行时错误
  if (signUpButton && signInButton && container) {
    signUpButton.addEventListener("click", () => {
      container.classList.add("right-panel-active");
    });

    signInButton.addEventListener("click", () => {
      container.classList.remove("right-panel-active");
    });
  } else {
    console.error("DOM not found.");
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[8] || (_cache[8] = _createStaticVNode("<h1 data-v-449eb0df>Create Account</h1><div class=\"social-container\" data-v-449eb0df><a href=\"#\" class=\"social\" data-v-449eb0df><i class=\"fab fa-alipay\" data-v-449eb0df></i></a><a href=\"#\" class=\"social\" data-v-449eb0df><i class=\"fab fa-qq\" data-v-449eb0df></i></a><a href=\"#\" class=\"social\" data-v-449eb0df><i class=\"fab fa-weixin\" data-v-449eb0df></i></a></div><span data-v-449eb0df>or use your email for registration</span>", 3)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "请输入邮箱",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((registerData.userAccount) = $event))
          }, null, 512), [
            [_vModelText, registerData.userAccount]
          ]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "验证码",
              class: "emailcode",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((registerData.captcha) = $event))
            }, null, 512), [
              [_vModelText, registerData.captcha]
            ]),
            _createElementVNode("button", {
              ref_key: "getRegisterCodeButton",
              ref: getRegisterCodeButton,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (getRegisterCode()))
            }, _toDisplayString(BUttonText.value), 513)
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            placeholder: "请输入密码",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((registerData.userPassword) = $event))
          }, null, 512), [
            [_vModelText, registerData.userPassword]
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            placeholder: "请确认密码",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((registerData.rePassword) = $event))
          }, null, 512), [
            [_vModelText, registerData.rePassword]
          ]),
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (regist()))
          }, "注     册")
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[9] || (_cache[9] = _createStaticVNode("<h1 data-v-449eb0df>Sign in</h1><div class=\"social-container\" data-v-449eb0df><a href=\"#\" class=\"social\" data-v-449eb0df><i class=\"fab fa-alipay\" data-v-449eb0df></i></a><a href=\"#\" class=\"social\" data-v-449eb0df><i class=\"fab fa-qq\" data-v-449eb0df></i></a><a href=\"#\" class=\"social\" data-v-449eb0df><i class=\"fab fa-weixin\" data-v-449eb0df></i></a></div><span data-v-449eb0df>or use your account</span>", 3)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "账号",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((loginData.userAccount) = $event))
          }, null, 512), [
            [_vModelText, loginData.userAccount]
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            placeholder: "密码",
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((loginData.userPassword) = $event))
          }, null, 512), [
            [_vModelText, loginData.userPassword]
          ]),
          _cache[10] || (_cache[10] = _createElementVNode("a", { href: "#" }, "Forgot your password?", -1)),
          _createElementVNode("button", { onClick: login }, "登     录")
        ])
      ]),
      _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"overlay-container\" data-v-449eb0df><div class=\"overlay\" data-v-449eb0df><div class=\"overlay-panel overlay-left\" data-v-449eb0df><h1 data-v-449eb0df>Welcome Back!</h1><p data-v-449eb0df> To keep connected with us please login with your personal info </p><button class=\"ghost\" id=\"signIn\" data-v-449eb0df> 登     录 </button></div><div class=\"overlay-panel overlay-right\" data-v-449eb0df><h1 data-v-449eb0df>Hello, Friend!</h1><p data-v-449eb0df>Enter your personal details and start journey with us</p><button class=\"ghost\" id=\"signUp\" data-v-449eb0df> 注     册 </button></div></div></div>", 1))
    ])
  ]))
}
}

})