import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "header-menu" }
const _hoisted_2 = { class: "search-bar" }
const _hoisted_3 = {
  key: 0,
  class: "admin header-menu"
}
const _hoisted_4 = { class: "header-profile" }
const _hoisted_5 = {
  key: 0,
  class: "avatar-bar"
}
const _hoisted_6 = {
  key: 1,
  class: "header-menu"
}

import router from "@/router";
import { onBeforeMount, ref } from "vue";
import { useUserStore } from "@/store/UserStore";
import { useCommonStore } from "@/store/CommonStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderMenu',
  setup(__props) {

const userStore = useUserStore();
const commonStore = useCommonStore();
const userInfo = ref();

const handleCommand = (command: string | number | object) => {
  router.push({
    path: command as string,
  });
};

const seleteKey = ref(["/"]);
// 选中项
// 搜索选中是置true
const isWide = ref(false);
const userBar = ref([
  {
    name: "首页",
    path: "/home",
  },
  {
    name: "活动",
    path: "/activity",
  },
  {
    name: "题库",
    path: "/problem",
  },
]);
const adminBar = ref([
  {
    name: "管理",
    path: "/admin",
  },
]);

// const visibleRoutes = computed(() => {
//   if (!userStore.userRole.includes("admin")) return [];
//   const routes = router.getRoutes();
//   return routes.filter((item, index) => {
//     if (item.meta?.isHide === true) {
//       return false;
//     }
//     if (item.meta?.premission === PERMISSION_ENUM.MANGE) {
//       return true;
//     } else return false;
//   });
// });

const changeTheme = () => {
  document.body.classList.toggle("light-mode");
};

const logout = () => {
  userStore.clearUserInfo();
};

onBeforeMount(() => {
  userInfo.value = userStore.userInfo;
});
//监听路由跳转，路由跳转后
router.afterEach((to, from, failure) => {
  seleteKey.value = [to.path];
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    id: "header",
    class: _normalizeClass({ wide: isWide.value })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(userBar.value, (item, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: index,
          index: index,
          to: item.path,
          class: _normalizeClass({ 'is-active': index === _unref(commonStore).isActiveIndexOfBar })
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.name), 1)
          ]),
          _: 2
        }, 1032, ["index", "to", "class"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: "text",
        placeholder: "Search",
        onFocus: _cache[0] || (_cache[0] = ($event: any) => (isWide.value = true)),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (isWide.value = false))
      }, null, 32)
    ]),
    (
        _unref(userStore).userRole.includes('admin') ||
        _unref(userStore).userRole.includes('root')
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adminBar.value, (item, index) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              key: index,
              index: index,
              to: item.path,
              class: _normalizeClass({ 'is-active': index + 3 === _unref(commonStore).isActiveIndexOfBar })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["index", "to", "class"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: "dark-light",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (changeTheme()))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("svg", {
          viewBox: "0 0 24 24",
          stroke: "currentColor",
          "stroke-width": "1.5",
          fill: "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }, [
          _createElementVNode("path", { d: "M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z" })
        ], -1)
      ])),
      (_unref(userStore).userInfo.userAccount !== null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_el_dropdown, {
              trigger: "click",
              onCommand: handleCommand
            }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_dropdown_item, { command: "/user/profile" }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("个人信息 ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_dropdown_item, {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (logout()))
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("退出登录")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_avatar, {
                  shape: "square",
                  src: _unref(userStore).userInfo.avatar
                }, null, 8, ["src"])
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: "/login",
              class: _normalizeClass({ 'is-active': 10 === _unref(commonStore).isActiveIndexOfBar }),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(commonStore).setActiveIndex(10)))
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("登录 ")
              ])),
              _: 1
            }, 8, ["class"])
          ]))
    ])
  ], 2))
}
}

})