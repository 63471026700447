/**
 * 权限定义
 */

const PERMISSION_ENUM = {
  SUBMIT: "submit",
  POST_MESSAGE: "post_message",
  DISCUSS: "discuss",
  MANGE: "mange",
  PROBLEM_MANGE: "problem_mange",
  REPLY: "reply",
  NO: "no",
};
export default PERMISSION_ENUM;
