import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "mdeditor" }

import gfm from "@bytemd/plugin-gfm";
import highlight from "@bytemd/plugin-highlight";
import math from "@bytemd/plugin-math-ssr";
import { Editor } from "@bytemd/vue-next";

interface Props {
  value: string;
  handleChange: (v: string) => void;
}

/**
 * 给组件指定初始值
 */
// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'MdEditor',
  props: {
    value: { default: () => "" },
    handleChange: { type: Function, default: (v: string) => {
    console.log(v);
  } }
  },
  setup(__props: any) {

const plugins = [
  gfm(),
  math(),
  highlight(),
  // Add more plugins here
];
const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Editor), {
      class: "bytemd",
      value: _ctx.value,
      plugins: plugins,
      onChange: _ctx.handleChange
    }, null, 8, ["value", "onChange"])
  ]))
}
}

})