import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "home" }
const _hoisted_2 = { style: {"display":"flex"} }
const _hoisted_3 = {
  class: "card-header",
  style: {"width":"470px"}
}

import {
  AnnouncementControllerService,
  AnnouncementTitleVO,
} from "../../generated";
import { onMounted, ref } from "vue";
import { ElMessage } from "element-plus";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const tableData = ref([] as AnnouncementTitleVO[]);
const loadData = async () => {
  const res = await AnnouncementControllerService.getAnnouncementList();
  if (res.code === 0) {
    tableData.value = res.data as AnnouncementTitleVO[];
  } else {
    ElMessage.error("加载公告栏失败");
  }
};

onMounted(() => {
  loadData();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_card, { style: {"min-width":"510px","margin-left":"20px","border-radius":"10px"} }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("span", null, "公告栏", -1)),
            _createVNode(_component_router_link, {
              style: {"margin-left":"350px"},
              class: "rlink"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("查看更多")
              ])),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_table, {
            data: tableData.value,
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, { label: "标题" }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_router_link, {
                    style: {"font-weight":"500"},
                    class: "rlink",
                    to: {
                  name: 'AnnouncementView',
                  query: { id: scope.row.id },
                }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(scope.row.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                prop: "createTime",
                label: "发布时间"
              })
            ]),
            _: 1
          }, 8, ["data"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})