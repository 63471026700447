import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "add-activity" }
const _hoisted_2 = { class: "panel-body" }

import { onMounted, ref, watch } from "vue";
import { ElMessage } from "element-plus";
import { ActivityControllerService } from "../../../generated/services/ActivityControllerService";
import MdEditor from "@/components/MdEditor.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddActivityView',
  setup(__props) {

const avatarData = ref({
  activityTitle: "",
});

const activityAvatarUploadPath = ref("");

// 新建课程请求体
const form = ref({
  title: "",
  avatar: "",
  description: "",
});

// 图片上传成功，回调函数
const handleAvatarSuccess = (response) => {
  if (response.code === 0) {
    form.value.avatar = response.data;
  } else {
    ElMessage.error("上传失败：{}", response.message);
  }
};

const onContentMdchange = (v: string) => {
  form.value.description = v;
};

const onSubmit = async () => {
  const res = await ActivityControllerService.addActivity(form.value);
  if (res.code === 0) {
    ElMessage.success("添加成功");
  } else {
    ElMessage.error("添加失败：" + res.message);
  }
};

watch(
  () => form.value.title,
  (newValue) => {
    avatarData.value.activityTitle = newValue as string;
  }
);

onMounted(() => {
  activityAvatarUploadPath.value = process.env
    .VUE_APP_ACTIVITY_AVATAR_UPLOAD_PATH as string;
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_form, {
        model: form.value,
        "label-width": "auto",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "标题" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: form.value.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.title) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "内容" }, {
            default: _withCtx(() => [
              _createVNode(MdEditor, {
                value: form.value.description,
                "handle-change": onContentMdchange
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_upload, {
            class: "upload-demo",
            drag: "",
            action: activityAvatarUploadPath.value,
            "on-success": handleAvatarSuccess,
            data: avatarData.value
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("i", { class: "el-icon-upload" }, null, -1),
              _createElementVNode("div", { class: "el-upload__text" }, [
                _createTextVNode("将图片上传至此，或"),
                _createElementVNode("em", null, "点击上传")
              ], -1)
            ])),
            _: 1
          }, 8, ["action", "data"]),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (onSubmit()))
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("提交 ")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ]))
}
}

})