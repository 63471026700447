import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "courses" }
const _hoisted_2 = { class: "panel-body" }
const _hoisted_3 = ["src"]

import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { ActivityControllerService } from "../../../generated/services/ActivityControllerService";
import { ElMessage } from "element-plus";


export default /*@__PURE__*/_defineComponent({
  __name: 'ActivityView',
  setup(__props) {

const data = ref();
const router = useRouter();

const loadData = async () => {
  const res = await ActivityControllerService.getActivityList();
  if (res.code === 0) {
    data.value = res.data;
  } else {
    ElMessage.error("获取数据失败：" + res.message);
  }
};

const goto = (id: number) => {
  router.push({
    path: "/activity/content",
    query: {
      id: id,
    },
  });
};

onMounted(() => {
  loadData();
});

return (_ctx: any,_cache: any) => {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "coursesdefault" }, [
        _createElementVNode("h2", null, "WOJ活动")
      ], -1)),
      _createVNode(_component_a_list, { class: "dataBox" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value, (item, index) => {
            return (_openBlock(), _createBlock(_component_a_list_item, {
              key: index,
              onClick: ($event: any) => (goto(item.id))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_list_item_meta, {
                  title: item.title,
                  description: item.description
                }, {
                  avatar: _withCtx(() => [
                    _createVNode(_component_a_avatar, { shape: "square" }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          alt: "avatar",
                          src: item.avatar
                        }, null, 8, _hoisted_3)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["title", "description"])
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}
}

})