import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "userprofile" }
const _hoisted_2 = { class: "panel-body" }

import { onBeforeMount, reactive } from "vue";
import { useUserStore } from "@/store/UserStore";
import { UserControllerService } from "../../../generated";
import { ElMessage } from "element-plus";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserProfileView',
  setup(__props) {

const userStore = useUserStore();

let userProfile = reactive({
  userAccount: "",
  userName: "",
  userProfile: "",
  school: "",
  course: "",
  number: "",
  gender: "",
  github: "",
  blog: "",
  avatar: "",
  signature: "",
  titleName: "",
  titleColor: "",
  createTime: "",
});

const onSubmit = async () => {
  const res = await UserControllerService.updateProfile(userProfile);
  if (res.code === 0) {
    ElMessage.success("修改成功");
  } else {
    ElMessage.error("修改失败" + res.data);
  }
};
const loadData = () => {
  userProfile = userStore.userInfo as any;
};

onBeforeMount(() => {
  loadData();
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[10] || (_cache[10] = _createElementVNode("div", null, [
        _createElementVNode("h2", null, "个人信息")
      ], -1)),
      _createVNode(_component_el_form, {
        model: _unref(userProfile),
        "label-width": "auto",
        style: {"max-width":"600px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "账号" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: "",
                modelValue: _unref(userProfile).userAccount,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(userProfile).userAccount) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "用户名" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _unref(userProfile).userName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(userProfile).userName) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "用户简介" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _unref(userProfile).userProfile,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(userProfile).userProfile) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "github地址" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _unref(userProfile).github,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(userProfile).github) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "博客地址" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _unref(userProfile).blog,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(userProfile).blog) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "性别" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _unref(userProfile).gender,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(userProfile).gender) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_option, {
                    label: "保密",
                    value: "保密"
                  }),
                  _createVNode(_component_el_option, {
                    label: "男",
                    value: "男"
                  }),
                  _createVNode(_component_el_option, {
                    label: "女",
                    value: "女"
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "学校" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _unref(userProfile).school,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(userProfile).school) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "个性签名" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _unref(userProfile).signature,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(userProfile).signature) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: onSubmit
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("修改")
                ])),
                _: 1
              }),
              _createVNode(_component_el_button, null, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Cancel")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ]))
}
}

})