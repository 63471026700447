import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "basicLayout" }
const _hoisted_2 = { class: "common-layout" }
const _hoisted_3 = { class: "content-wrapper" }

import HeaderMenu from "@/components/HeaderMenu.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'BasicLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_aside, {
            width: "200px",
            class: "left-side"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("side")
            ])),
            _: 1
          }),
          _createVNode(_component_el_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_header, { class: "header" }, {
                default: _withCtx(() => [
                  _createVNode(HeaderMenu)
                ]),
                _: 1
              }),
              _createVNode(_component_el_main, { class: "main-container" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_router_view)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}
}

})