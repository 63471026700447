import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "sidebar" }

import { reactive } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SideBar',
  setup(__props) {

const barData = reactive([
  {
    title: "用户管理",
    index: "1",
    child: [
      {
        title: "增删改查",
        child: [
          {
            title: "用户列表",
            index: "/admin/userlist",
          },
          {
            title: "添加管理",
            index: "/admin/addmanager",
          },
        ],
      },
    ],
  },
  {
    title: "题目管理",
    index: "2",
    child: [
      {
        title: "增删改查",
        child: [
          {
            title: "新建课程",
            index: "/admin/addcourse",
          },
          {
            title: "新建题目",
            index: "/admin/addproblem",
          },
          {
            title: "新建活动",
            index: "/admin/addactivity",
          },
          {
            title: "新建公告",
            index: "/admin/addannouncement",
          },
        ],
      },
    ],
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_location = _resolveComponent("location")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, { class: "tac" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_menu, {
              router: "",
              "default-active": "1",
              class: "el-menu-vertical-demo"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(barData, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_sub_menu, {
                    key: index,
                    index: index
                  }, {
                    title: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_location)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("span", null, _toDisplayString(item.title), 1)
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.child, (item2, index2) => {
                        return (_openBlock(), _createBlock(_component_el_menu_item_group, {
                          key: index2,
                          title: item2.title
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.child, (item3, index3) => {
                              return (_openBlock(), _createBlock(_component_el_menu_item, {
                                key: index3,
                                index: item3.index
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item3.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["index"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["title"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["index"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})