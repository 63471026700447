import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "problem-content" }
const _hoisted_2 = { class: "panel-body" }
const _hoisted_3 = { class: "content" }

import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import {
  ProblemControllerService,
  ProblemSubmitControllerService,
} from "../../../generated";
import { ElMessage } from "element-plus";
import CodeEditor from "@/components/CodeEditor.vue";
import { Viewer } from "@bytemd/vue-next";
import gfm from "@bytemd/plugin-gfm";
import math from "@bytemd/plugin-math-ssr";
import highlight from "@bytemd/plugin-highlight";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProblemContentView',
  setup(__props) {

const route = useRoute();
const isState = ref(true);
const isHide = ref(true);
const message = ref("");
const codeEditor = ref();

const plugins = [
  gfm(),
  math(),
  highlight(),
  // Add more plugins here
];

const problemData = ref({
  id: 0,
  problemId: 0,
  title: 0,
  author: "",
  tagList: [],
  description: "",
  input: "",
  output: "",
  source: "",
  difficulty: 0,
  auth: 0,
});

const submitData = ref({
  language: "c++",
  code: "",
  pid: 0,
});

const submit = async () => {
  if (
    codeEditor.value.codeEditorData === null ||
    codeEditor.value.codeEditorData === ""
  ) {
    ElMessage.error("代码不能为空");
    return;
  }
  isState.value = false;
  isHide.value = false;
  //先清空以前的数据
  message.value = "";
  submitData.value.code = codeEditor.value.codeEditorData;
  const result = await ProblemSubmitControllerService.doSubmit(
    submitData.value
  );
  isState.value = true;
  if (result.code === 0) {
    message.value = result.message ?? "";
    ElMessage.success("提交成功");
  } else {
    ElMessage.error("提交失败：" + result.message);
  }
};

const loadData = async () => {
  const id = route.query.id;
  if (!id) {
    return;
  }
  const res = await ProblemControllerService.getProblem(id as any);
  if (res.code === 0) {
    problemData.value = res.data as any;
  } else {
    ElMessage.error("加载失败：" + res.message);
  }
};
onBeforeMount(() => {
  loadData();
  submitData.value.pid = route.query.id as any;
});

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(problemData.value.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Viewer), {
          value: problemData.value.description,
          plugins: plugins
        }, null, 8, ["value"])
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", null, [
        _createVNode(CodeEditor, {
          ref_key: "codeEditor",
          ref: codeEditor
        }, null, 512)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_a_button, { onClick: submit }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("提交")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([isHide.value == true ? 'cardIsHide' : 'cardNoHide'])
      }, [
        _cache[1] || (_cache[1] = _createTextVNode(" 代码提交状态： ")),
        _createVNode(_component_a_spin, {
          class: _normalizeClass([isState.value == true ? 'isHide' : 'noHide'])
        }, null, 8, ["class"]),
        _createTextVNode(" " + _toDisplayString(message.value), 1)
      ], 2)
    ])
  ]))
}
}

})