import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "announcement-content" }
const _hoisted_2 = { class: "panel-body" }
const _hoisted_3 = { style: {"text-align":"center"} }
const _hoisted_4 = { class: "footer" }
const _hoisted_5 = { class: "content" }

import { onMounted, ref } from "vue";
import {
  AnnouncementContentVO,
  AnnouncementControllerService,
} from "../../../generated";
import { ElMessage } from "element-plus";
import { useRoute } from "vue-router";
import { Viewer } from "@bytemd/vue-next";


export default /*@__PURE__*/_defineComponent({
  __name: 'AnnouncementView',
  setup(__props) {

const route = useRoute();
const announcementData = ref([] as AnnouncementContentVO);

const loadData = async () => {
  const id = Number(route.query.id);
  if (!id) {
    return;
  }
  const res = await AnnouncementControllerService.getAnnouncement(id as number);
  if (res.code === 0) {
    announcementData.value = res.data as any;
  } else {
    ElMessage.error("加载公告失败");
  }
};

onMounted(() => {
  loadData();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(announcementData.value.title), 1),
      _createElementVNode("div", _hoisted_4, "发布于： " + _toDisplayString(announcementData.value.createTime), 1),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_unref(Viewer), {
          value: announcementData.value.content
        }, null, 8, ["value"])
      ])
    ])
  ]))
}
}

})