import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "main" }

import BasicLayout from "@/layout/BasicLayout.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "video-bg" }, [
      _createElementVNode("video", {
        width: "320",
        height: "240",
        autoplay: "",
        loop: "",
        muted: ""
      }, [
        _createElementVNode("source", {
          src: "https://assets.codepen.io/3364143/7btrrd.mp4",
          type: "video/mp4"
        })
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(BasicLayout)
    ])
  ], 64))
}
}

})