import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "add-announcement" }
const _hoisted_2 = { class: "panel-body" }

import { ref } from "vue";
import { ElMessage } from "element-plus";
import MdEditor from "@/components/MdEditor.vue";
import {
  AnnouncementAddDTO,
  AnnouncementControllerService,
} from "../../../generated";

// 新建课程请求体

export default /*@__PURE__*/_defineComponent({
  __name: 'AddAnnouncementView',
  setup(__props) {

const form = ref({
  title: "",
  content: "",
} as AnnouncementAddDTO);

const onContentMdchange = (v: string) => {
  form.value.content = v;
};

const onSubmit = async () => {
  const res = await AnnouncementControllerService.addAnnouncement(form.value);
  if (res.code === 0) {
    ElMessage.success("添加成功");
  } else {
    ElMessage.error("添加失败：" + res.message);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_form, {
        model: form.value,
        "label-width": "auto",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "标题" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: form.value.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.title) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "内容" }, {
            default: _withCtx(() => [
              _createVNode(MdEditor, {
                value: form.value.content,
                "handle-change": onContentMdchange
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (onSubmit()))
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("提交 ")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ]))
}
}

})