import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "problem" }
const _hoisted_2 = { class: "panel-body" }
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { style: {"display":"inline-flex"} }
const _hoisted_5 = { class: "tag-text" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }

import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { ProblemControllerService } from "../../../generated/services/ProblemControllerService";
import { ElMessage } from "element-plus";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProblemListView',
  setup(__props) {

const current = ref(1);
const size = ref(10);
const total = ref(0);
const finished = ref(false);

const problemList = ref();

const columns = ref([
  {
    title: "Id",
    dataIndex: "id",
  },
  {
    title: "标题",
    dataIndex: "title",
    slotName: "title",
  },
  {
    title: "标签",
    dataIndex: "tags",
    slotName: "tags",
  },
  {
    title: "难度",
    dataIndex: "difficulty",
    slotName: "difficulty",
  },
]);

const tagList = ref([]);

const levels = ref([
  {
    index: 0,
    label: "简单",
    color: "#BFBFBF",
  },
  {
    index: 1,
    label: "适中",
    color: "#FE4C61",
  },
  {
    index: 2,
    label: "困难",
    color: "#FFC116",
  },
]);

const router = useRouter();
const gotoContentById = (id: string) => {
  router.push({
    path: "/problem/content",
    query: {
      id: id,
    },
  });
};
const gotoEditById = (id: string) => {
  router.push({
    path: "/admin/addproblem",
    query: {
      id: id,
      update: 1,
    },
  });
};

const filter = ref({
  title: null,
  difficulty: null,
  tags: [],
  id: null,
});

const getQuestion = async () => {
  finished.value = false;
  const res = await ProblemControllerService.searchProblemTitleTwo(
    current.value,
    size.value,
    filter.value.id as any,
    filter.value.tags as any,
    filter.value.difficulty as any,
    filter.value.title as any
  );
  if (res.code === 0) {
    total.value = res.data?.total;
    problemList.value = res.data?.records;
    finished.value = true;
  } else {
    ElMessage.error("获取题目列表失败" + res.message);
  }
};
const getTagList = async () => {
  const res = await ProblemControllerService.getProblemTagList();
  if (res.code !== 0) {
    ElMessage.error("获取题目标签失败");
  } else {
    tagList.value = res.data;
  }
};
const handleCurrentChange = (val: number) => {
  current.value = val;
  getQuestion();
};
const clear = () => {
  filter.value.difficulty = null;
  filter.value.title = null;
  filter.value.tags = [];
  filter.value.id = null;
  getQuestion();
};

onMounted(() => {
  getQuestion();
  getTagList();
});

return (_ctx: any,_cache: any) => {
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_card = _resolveComponent("el-card")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[15] || (_cache[15] = _createElementVNode("h2", null, "在线题库", -1)),
      _createVNode(_component_el_card, {
        class: "box-card",
        shadow: "hover"
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_pagination, {
              onCurrentChange: handleCurrentChange,
              "current-page": current.value,
              "page-size": size.value,
              total: total.value,
              layout: "total, prev, pager, next"
            }, null, 8, ["current-page", "page-size", "total"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_form, {
              inline: true,
              model: filter.value
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: filter.value.id,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filter.value.id) = $event)),
                      type: "text",
                      placeholder: "id",
                      style: {"width":"50px"}
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: filter.value.title,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filter.value.title) = $event)),
                      type: "text",
                      placeholder: "题目标题",
                      style: {"width":"150px"},
                      onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (getQuestion()), ["enter"]))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: filter.value.difficulty,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filter.value.difficulty) = $event)),
                      placeholder: "难度评级",
                      style: {"width":"120px"},
                      onChange: _cache[4] || (_cache[4] = ($event: any) => (getQuestion()))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(levels.value, (it) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: it.index,
                            label: it.label,
                            value: it.index
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: filter.value.tags,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((filter.value.tags) = $event)),
                      multiple: "",
                      filterable: "",
                      clearable: "",
                      placeholder: "题目标签",
                      style: {"width":"350px"},
                      onChange: _cache[6] || (_cache[6] = ($event: any) => (getQuestion()))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tagList.value, (tag) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: tag,
                            label: tag.name,
                            value: tag.name
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_tag, { type: "info" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_5, _toDisplayString(tag.name), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"]),
            _createVNode(_component_el_button_group, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (getQuestion()))
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("筛选记录 ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "success",
                  onClick: clear
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode(" 显示全部")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _withDirectives((_openBlock(), _createBlock(_component_el_table, {
            class: "list",
            data: problemList.value,
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                fixed: "",
                prop: "id",
                label: "id",
                width: "100"
              }),
              _createVNode(_component_el_table_column, {
                prop: "title",
                label: "题目",
                width: "200"
              }),
              _createVNode(_component_el_table_column, {
                prop: "tagList",
                label: "标签",
                width: "250"
              }, {
                default: _withCtx((scope) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.tagList, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _createVNode(_component_el_tag, { type: "success" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                prop: "difficulty",
                label: "难度",
                width: "100"
              }, {
                default: _withCtx((scope) => [
                  (scope.row.difficulty === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_el_tag, { type: "success" }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode("简单")
                          ])),
                          _: 1
                        })
                      ]))
                    : (scope.row.difficulty === 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_el_tag, { type: "warning" }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode("适中")
                            ])),
                            _: 1
                          })
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_el_tag, { type: "danger" }, {
                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                              _createTextVNode("困难")
                            ])),
                            _: 1
                          })
                        ]))
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                fixed: "right",
                label: "操作",
                "min-width": "20"
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: ($event: any) => (gotoContentById(scope.row.id))
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode(" 学习 ")
                    ])),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: ($event: any) => (gotoEditById(scope.row.id))
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode(" 编辑 ")
                    ])),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])), [
            [_directive_loading, !finished.value]
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})