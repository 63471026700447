import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import * as monaco from "monaco-editor";
import { onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CodeEditor',
  setup(__props, { expose: __expose }) {

const codeEditorRef = ref();
const codeEditorData = ref();

__expose({
  codeEditorData,
});

onMounted(() => {
  if (!codeEditorRef.value) {
    return;
  }
  const editor = monaco.editor.create(codeEditorRef.value, {
    value: codeEditorData.value,
    language: "c", // 语言
    // automaticLayout: true, // 宽度自适应
    lineNumbers: "on", // 行号
    roundedSelection: false,
    scrollBeyondLastLine: true,
    readOnly: false,
    theme: "vs-dark",
  });
  const model = editor.getModel();
  model?.onDidChangeContent(() => {
    codeEditorData.value = model?.getValue();
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "code-editor",
    ref_key: "codeEditorRef",
    ref: codeEditorRef,
    style: {"min-height":"500px"}
  }, null, 512))
}
}

})