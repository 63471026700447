import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "activity-content" }
const _hoisted_2 = { class: "panel-body" }
const _hoisted_3 = { style: {"text-align":"center"} }
const _hoisted_4 = { class: "footer" }
const _hoisted_5 = { class: "content" }

import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { ActivityControllerService } from "../../../generated/services/ActivityControllerService";
import { ActivityContentVO } from "../../../generated/models/ActivityContentVO";
import { Viewer } from "@bytemd/vue-next";


export default /*@__PURE__*/_defineComponent({
  __name: 'ActivityContentView',
  setup(__props) {

const route = useRoute();
const activityData = ref({
  id: 0,
  title: "",
  description: "",
  createTime: "",
} as ActivityContentVO);

const loadData = async () => {
  const id = Number(route.query.id);
  if (!id) {
    return;
  }
  const res = await ActivityControllerService.getActivity(id);
  if (res.code === 0) {
    activityData.value = res.data as ActivityContentVO;
  } else {
    ElMessage.error("加载失败：" + res.message);
  }
};

onBeforeMount(() => {
  loadData();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(activityData.value.title), 1),
      _createElementVNode("div", _hoisted_4, "发布于： " + _toDisplayString(activityData.value.createTime), 1),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_unref(Viewer), {
          value: activityData.value.description
        }, null, 8, ["value"])
      ])
    ])
  ]))
}
}

})